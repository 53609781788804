<template>
  <div class="flex items-center justify-center h-full">
    <div class="flex items-center justify-center gap-4 flex-col">
      <h1 class="text-2xl font-bold">Register</h1>
      <vs-input block v-model="username" placeholder="Username *">
        <template #icon>
          <i class="bx bx-user" />
        </template>
        <template v-if="username && !validUsername" #message-danger>
          Invalid Username
        </template>
      </vs-input>

      <vs-input block v-model="email" placeholder="Email *">
        <template #icon>
          <i class="bx bx-mail-send" />
        </template>
        <template v-if="email && !validEmail" #message-danger>
          Invalid Email
        </template>
      </vs-input>

      <vs-input
        block
        type="password"
        v-model="password"
        placeholder="Password *"
      >
        <template #icon>
          <i class="bx bx-lock-open-alt" />
        </template>
        <template v-if="password && !validPassword" #message-danger>
          Invalid Password!
        </template>
      </vs-input>

      <vs-input
        block
        type="password"
        v-model="password2"
        placeholder="Repeat Password *"
      >
        <template #icon>
          <i class="bx bx-lock-open-alt" />
        </template>
        <template v-if="password2 && !passwordsMatch" #message-danger>
          Passwords doesn't match!
        </template>
      </vs-input>

      <vs-input
        block
        type="code"
        v-model="referrer"
        placeholder="Inviter (Optional)"
      >
        <template #icon>
          <i class="bx bx-hash" />
        </template>
      </vs-input>

      <vue-hcaptcha
        ref="captcha"
        sitekey="99cde083-e013-4c11-aa2a-f715a1a27fbb"
        @verify="captcha = $event"
        @expired="captcha = undefined"
        theme="dark"
      ></vue-hcaptcha>

      <vs-button
        :disabled="!passwordsMatch && validPassword && validUsername"
        block
        @click="onRegisterButtonClick"
      >
        Register
      </vs-button>
      <div class="text-xs">
        <span> You already have an account? </span>
        <router-link to="/login" class="ml-1.5 text-success hover:underline">
          Login
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
import api from "@/services/HttpService";

export default {
  name: "Register",
  components: { VueHcaptcha },
  data() {
    return {
      username: undefined,
      email: undefined,
      password: undefined,
      password2: undefined,
      referrer: undefined,
      captcha: undefined,
    };
  },
  computed: {
    validUsername() {
      const username = this.username;
      return username && username.length > 2 && username.length < 20;
    },
    validEmail() {
      const email = this.email;
      return email && email.length > 10 && email.length < 80;
    },
    validPassword() {
      const password = this.password;
      return password && password.length > 7 && password.length < 128;
    },
    passwordsMatch() {
      return this.password === this.password2;
    },
  },
  methods: {
    onRegisterButtonClick: function () {
      if (!this.captcha) {
        this.$vs.notification({
          position: "bottom-right",
          name: "Error",
          color: "danger",
          text: `Please complete captcha challenge.`,
        });
        return;
      }
      this.$refs.captcha.reset();
      const loading = this.$vs.loading({
        background: "rgba(0, 0, 0, 0.6)",
        color: "primary",
        type: "points",
      });
      api
        .Post("user", {
          username: this.username,
          password: this.password,
          email: this.email,
          referrer:
            this.referrer !== undefined && this.referrer !== ""
              ? this.referrer
              : undefined,
          captcha: this.captcha,
        })
        .then(() => {
          loading.close();
          this.$router.push("/login");
          this.$vs.notification({
            name: "Success",
            text: "Successfully registered.",
            color: "success",
            position: "bottom-right",
          });
        })
        .catch((error) => {
          loading.close();
          this.$vs.notification({
            name: "Error",
            text: error.response.data.message,
            color: "danger",
            position: "bottom-right",
          });
        });
    },
  },
};
</script>
